import {
  AccountCircle as AccountCircleIcon,
  AddTask as AddTaskIcon,
  CheckCircle,
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
} from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import { green } from '@mui/material/colors'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { autoRslaWorkSheetSelectors } from '@src/data/store/AutoRslaWorksheet'
import { get } from 'lodash-es'
import { Breadcrumb, IconButtonWithTooltip, PageError, PageSpinner } from '../../components'
import ConfirmActionDialog from '../../components/ConfirmActionDialog'
import PendingMessage from '../../components/PendingMessage'
import MerchantDialog from '../../components/SelectMerchantDialog'
import { EditBankAccountDto } from '../../data/api/credit-application/EditBankAccountDto'
import { EditIncomesDto } from '../../data/api/credit-application/EditIncomesDto'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { autoworksheetSelectors } from '../../data/store/AutoWorksheet'
import { b2cWorksheetEffects } from '../../data/store/B2cWorksheet'
import { b2cWorksheetSelectors } from '../../data/store/B2cWorksheet/b2c-worksheet-store'
import { creditActions, creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import { documentSelectors } from '../../data/store/Document'
import { electronicSignatureEffects, electronicSignatureSelectors } from '../../data/store/ElectronicSignature'
import {
  fullCreditApplicationEffects,
  setFullCreditApp,
} from '../../data/store/FullCreditApplication/full-credit-application-effects'
import { messageEffects, messageSelectors } from '../../data/store/Message'
import { personalLoanWorksheetEffects } from '../../data/store/PersonalLoanWorksheet'
import { personalLoanWorksheetSelectors } from '../../data/store/PersonalLoanWorksheet/personal-loan-worksheet-store'
import { requiredExternalStepEffects, requiredExternalStepSelectors } from '../../data/store/RequiredExternalStep'
import { userSelectors } from '../../data/store/UserStore'
import {
  Applicant,
  BankAccount,
  Constants,
  CreditApplicationMessage,
  CreditApplicationReservation,
  CreditApplicationReservationDto,
  CreditApplicationTask,
  CreditTaskComment,
  EApplicantType,
  ECreditApplicationStatus,
  EExternalStep,
  EExternalStepStatus,
  EFinancingProgram,
  EPaymentMethod,
  ETaskType,
  ETasksStatus,
  FullCreditApplication,
  Merchant,
  NormsMessage,
  RequiredExternalStep,
  RequiredExternalStepDto,
  creditTaskSchema,
} from '../../data/types'
import { BanReason } from '../../data/types/BanSchema'
import { UpdateCreditApplicationMerchantDto } from '../../data/types/UpdateCreditApplicationMerchantDto'
import { reportErrorToConsole } from '../../services/error-logger'
import B2cWorksheetInformationSection from './components/B2cWorksheetInformationSection'
import BanDialog from './components/BanDialog'
import CreditApplicationLockSideBar from './components/CreditApplicationLockSideBar'
import CreditApplicationPrivateMessageSideBar from './components/CreditApplicationPrivateMessageSideBar'
import EditBankAcountDialog from './components/EditBankAcountDialog'
import EditBankruptciesDialog from './components/EditBankruptciesDialog'
import EditExpenseDialog from './components/EditExpenseDialog'
import EditIncomesDialog from './components/EditIncomesDialog'
import EditTaskDialog from './components/EditTaskDialog'
import FinancementContractSection from './components/FinancingContractSection'
import IFinanceWorksheetHistoryDialog from './components/IFinanceWorksheetHistoryDialog'
import IncomeSection from './components/IncomeSection'
import MerchantPaymentInstructions from './components/MerchantPaymentInstructions'
import NewTaskDialog from './components/NewTaskCommentDialog'
import TaskList from './components/TaskList'
import ApplicationResume from './components/applicationResume'
import AutoWorksheetHistoryDialog from './components/autoWorksheetHistoryDialog'
import AutoWorksheetInformationSection from './components/autoWorksheetInformationSection'
import CreditDecisionHistoryDialog from './components/creditDecisionHistoryDialog'
import CreditSection from './components/creditSection'
import DocumentList from './components/documentList'
import OtherApplicationList from './components/otherApplicationList'
import PersonalWorksheetInformationSection from './components/personalWorksheetInformationSection'
import PrivateMessage from './components/privateMessage'
import WarningMessageAccordion from './components/warningMessageAccordion'
import AutoRslaWorksheetInformationSection from './components/AutoRslaWorksheetInformation'

enum Dialog {
  Job = 'jobDialog',
  Bankruptcies = 'bankruptciesDialog',
  Suppression = 'suppressionDialog',
  Comment = 'commentDialog',
  Task = 'taskDialog',
  Ban = 'banDialog',
  Merch = 'MerchantDialog',
  WorksheetHistory = 'WorksheetHistoryDialog',
  Expenses = 'ExpensesDialog',
  BankAccountInfo = 'BankAccountInfo',
  CreditDecisionHistory = 'creditDecisionHistory',
  None = '',
}

const ViewCreditApplicationPage = (): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id, financingProgramId } = useParams<{ id: string; financingProgramId: EFinancingProgram }>()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)

  const theme = useTheme()
  const error = useAppSelector(appSelectors.getBusinessError)
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()

  const [editingTask, setEditingTask] = useState<CreditApplicationTask>(
    creditTaskSchema.getDefault() as CreditApplicationTask,
  )
  const [showOnlyTodoTasks, setShowOnlyTodoTasks] = React.useState<boolean>(true)
  const [editingApplicant, setEditingApplicant] = useState<'applicant' | 'coApplicant'>('applicant')
  const [openDialog, setOpenDialog] = useState<Dialog>(Dialog.None)
  const [isTopSideBarOpen, setIsTopSideBarOpen] = useState<boolean>(false)
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const [cardHeight, setCardHeight] = useState(0)
  const isLoadingCreditApplication = useAppSelector(creditSelectors.isLoadingCreditApplication)

  const isLoadingLock = useAppSelector(creditSelectors.isLoadingLock)

  const isLoading = isLoadingCreditApplication || isLoadingLock
  const isLoadingPreviousApplicantApplication = useAppSelector(creditSelectors.isLoadingPreviousApplicantApplication)
  const isLoadingPreviousCoApplicantApplication = useAppSelector(
    creditSelectors.isLoadingPreviousCoApplicantApplication,
  )
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const isPrequalificationPending = useAppSelector(creditSelectors.isPrequalificationPending)
  const hasDocumentsPendingApproval = useAppSelector(documentSelectors.hasDocumentsPendingApproval)
  const continueFinancingDisableReasonList = useAppSelector(creditSelectors.canContinuePersonnalLoanFinancing)
  const creditTasks = useAppSelector(creditSelectors.getTaskListToDisplay)
  const areAllTasksRequiredForPayoutCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForPayoutCompleted)
  const areAllDocumentsRequiredForPayOut = useAppSelector(documentSelectors.areAllDocumentsRequiredForPayOut)
  const requiredExternalSteps = useAppSelector(requiredExternalStepSelectors.getRequiredExternalSteps)
  const creditApplicationMessage = useAppSelector(messageSelectors.getMessageList)
  const privateMessages = useAppSelector(messageSelectors.getPrivateMessages)
  const canConfirmIncome = useAppSelector(creditSelectors.getCanConfirmAllIncomes)
  const confirmAllIncomesStatus = useAppSelector(creditSelectors.getConfirmAllIncomesStatus)
  const autoWorksheet = useAppSelector(autoworksheetSelectors.getCurrent)
  const personalLoanWorksheet = useAppSelector(personalLoanWorksheetSelectors.getCurrent)
  const b2cWorksheet = useAppSelector(b2cWorksheetSelectors.getCurrent)
  const autoRslaWorksheet = useAppSelector(autoRslaWorkSheetSelectors.getCurrent)
  const autoWorksheetRevisions = useAppSelector(autoworksheetSelectors.getCurrentRevisions)
  const electronicSignatureStatus = useAppSelector(electronicSignatureSelectors.getElectronicSignatureStatus)
  const user = useAppSelector(userSelectors.getUser)
  const applicantNormsMessage: NormsMessage[] = useAppSelector(
    creditSelectors.getCurrentCreditApplicationNormsApplicant,
  )
  const coapplicantNormsMessage: NormsMessage[] = useAppSelector(
    creditSelectors.getCurrentCreditApplicationNormsCoapplicant,
  )
  const commonNormsMessage: NormsMessage[] = useAppSelector(creditSelectors.getCurrentCreditApplicationSpecificNorms)
  const applicantPreviousApplications = useAppSelector(creditSelectors.getPreviousApplicantApplication)
  const coApplicantPreviousApplications = useAppSelector(creditSelectors.getPreviousCoApplicantApplication)
  const worksheetNormsMessages = useAppSelector(autoworksheetSelectors.getCurrentWorksheetNormRefusalAndWarningMessages)
  const canLockCreditApplication = useAppSelector(creditSelectors.canChangeLockOfCreditApplication)
  const lock = useAppSelector(creditSelectors.getCreditApplicationLock)
  const applicationIsDraft = creditApplication?.status === ECreditApplicationStatus.Draft
  const isAbleSendFundDepositConfirmation = useAppSelector(documentSelectors.isAbleToSendFundDepositConfirmation)
  const applicationStatus = useAppSelector(creditSelectors.getProgressionStatus)
  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)
  const worksheetIsApproved = useAppSelector(personalLoanWorksheetSelectors.worksheetIsApproved)
  const editDisabled = creditApplication?.editLocked || finalDecisionIsPending
  const showPreviousApplication =
    !(isLoadingPreviousApplicantApplication || isLoadingPreviousCoApplicantApplication) &&
    (applicantPreviousApplications.length > 0 || coApplicantPreviousApplications.length > 0)
  const personalWorksheetRevisions = useAppSelector(personalLoanWorksheetSelectors.getCurrentRevisions)
  const b2cWorksheetRevisions = useAppSelector(b2cWorksheetSelectors.getCurrentRevisions)

  const financingProgramConfig = get(
    Constants.financingProgramConfigs,
    financingProgramId?.toLowerCase() ?? 'default',
    Constants.financingProgramConfigs.default,
  )

  const creditFilteredTasks = useMemo(() => {
    if (showOnlyTodoTasks) {
      return creditTasks.filter((x) => x.status === ETasksStatus.ToDo)
    }
    return creditTasks
  }, [creditTasks, showOnlyTodoTasks])

  const incomeTasks = useMemo(() => {
    return creditTasks.filter((x) => x.typeId === ETaskType.ConfirmJobIncome)
  }, [creditTasks])

  const otherIncomeTasks = useMemo(() => {
    return creditTasks.filter((x) => x.typeId === ETaskType.ConfirmOtherIncome)
  }, [creditTasks])

  const [disabledDialogButton, setDisabledDialogButton] = useState(false)

  const closeDialog = useCallback(() => {
    setOpenDialog(Dialog.None)
    setDisabledDialogButton(false)
  }, [setOpenDialog])

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    { path: '#', label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`) },
  ]

  const onMerchantChanged = useCallback(
    async (newMerchant: Merchant) => {
      const updatedMerchant = {
        versionTag: creditApplication?.versionTag,
        merchantId: newMerchant.id,
        creditApplicationId: creditApplication?.id,
        financingProgramId: creditApplication?.financingProgramId,
      } as UpdateCreditApplicationMerchantDto
      setDisabledDialogButton(true)
      await dispatchEffect(creditEffects.updateMerchant(updatedMerchant)).then(async () => {
        dispatch(creditActions.setMerchant(newMerchant))
        if (personalLoanWorksheet?.id)
          await dispatchEffect(personalLoanWorksheetEffects.getById(personalLoanWorksheet.creditApplicationId))
      })
      closeDialog()
    },
    [creditApplication, dispatchEffect, dispatch, closeDialog, personalLoanWorksheet],
  )

  const handleEditTaskDialog = useCallback((task: CreditApplicationTask) => {
    if (task.id) {
      setEditingTask(task)
    } else {
      setEditingTask(creditTaskSchema.getDefault() as CreditApplicationTask)
    }
    setOpenDialog(Dialog.Task)
  }, [])

  const handleSaveTask = useCallback(
    async (task: CreditApplicationTask) => {
      task.financingProgramId = financingProgramId
      setDisabledDialogButton(true)
      if (task.id) {
        await dispatchEffect(creditEffects.updateTask(task))
      } else {
        task.creditApplicationId = id
        await dispatchEffect(creditEffects.createTask(task))
      }
      closeDialog()
    },
    [id, closeDialog, dispatchEffect, financingProgramId],
  )

  function findRequiredStepsByApplicantTypeAndExternalStepId(data: EApplicantType, externalStepId: EExternalStep) {
    return requiredExternalSteps.find((element) => {
      return element.applicantType === data && element.externalStepId === externalStepId
    })
  }

  const handleResetdBankAccountRequest = useCallback(
    async (data: 'applicant' | 'coApplicant') => {
      if (creditApplication?.id) {
        const bankAccountRequestDto: RequiredExternalStepDto = {
          applicantType: data,
          creditApplicationId: creditApplication.id,
          financingProgramId: creditApplication.financingProgramId,
          contactClient: false,
        }
        await dispatchEffect(
          requiredExternalStepEffects.resetPersonalLoanBankAccountRequest(bankAccountRequestDto),
        ).then((ret: FullCreditApplication) => {
          setFullCreditApp(ret, dispatch)
        })
      }
    },
    [creditApplication, dispatch, dispatchEffect],
  )

  const handleSaveBankAccountRequest = async (data: EApplicantType) => {
    const isFound = requiredExternalSteps.find(
      (element: RequiredExternalStep) =>
        element.applicantType === data && element.externalStepId === EExternalStep.BankAccount,
    )
    if (creditApplication?.id) {
      const bankAccountRequestDto: RequiredExternalStepDto = {
        applicantType: data,
        creditApplicationId: creditApplication.id,
        financingProgramId: creditApplication.financingProgramId,
        contactClient: true,
      }
      if (isFound) {
        const requiredExternalStep = findRequiredStepsByApplicantTypeAndExternalStepId(data, EExternalStep.BankAccount)
        if (requiredExternalStep?.status === EExternalStepStatus.Completed) {
          const requestId = requiredExternalStep.extraProperties.thirdPartyRequestId
          window.open(
            `https://dashboard.flinks.com/en/534f3619-12c9-4cc3-a07e-c162239a1121/insights/requests/${requestId}`,
            '_blank',
            'noreferrer',
          )
        } else {
          await dispatchEffect(requiredExternalStepEffects.resendUrlForBankAccountRequest(bankAccountRequestDto))
        }
      } else {
        await dispatchEffect(requiredExternalStepEffects.createBankAccountRequest(bankAccountRequestDto))
      }
    }
  }

  const handleNewComment = useCallback((task: CreditApplicationTask) => {
    setEditingTask(task)
    setOpenDialog(Dialog.Comment)
  }, [])

  const handleShowOnlyTodoTasks = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyTodoTasks(event.target.checked)
  }

  const handleSaveTaskComment = async (comment: CreditTaskComment) => {
    comment.taskId = editingTask.id
    comment.financingProgramId = financingProgramId
    comment.creditApplicationId = editingTask.creditApplicationId ?? null
    setDisabledDialogButton(true)
    await dispatchEffect(creditEffects.createTaskComment(comment))
    closeDialog()
  }

  const handleStatusChange = useCallback(
    (status: ETasksStatus, task: CreditApplicationTask) => {
      const upTask = { ...task }
      upTask.status = status
      upTask.financingProgramId = financingProgramId
      return dispatchEffect(creditEffects.updateTask(upTask))
    },
    [dispatchEffect, financingProgramId],
  )

  const handleTaskDeletionClicked = (task: CreditApplicationTask) => {
    setEditingTask(task)
    setOpenDialog(Dialog.Suppression)
  }

  const handleTaskDeletionConfirmed = async () => {
    await dispatchEffect(creditEffects.deleteTask(editingTask, financingProgramId!))
    closeDialog()
  }

  const handleBanConfirmed = async (reason: BanReason) => {
    if (creditApplication) {
      const source = editingApplicant === 'coApplicant' ? creditApplication.coApplicant : creditApplication.applicant
      if (source) {
        const banishment = {
          ...reason,
          id: 0,
          firstName: source.firstName,
          lastName: source.lastName,
          birthDateString: source.birthDate.toString(),
          nas: source.sin,
          financingProgramId: creditApplication.financingProgramId,
        }

        await dispatchEffect(creditEffects.createBanishement(banishment))
        closeDialog()
      }
    }
  }

  const handleTopSideBarOpen = useCallback((topSideBarOpen: boolean) => {
    setIsTopSideBarOpen(topSideBarOpen)
  }, [])

  const handleJobUpdateClicked = (data: 'applicant' | 'coApplicant') => {
    setEditingApplicant(data)
    setOpenDialog(Dialog.Job)
  }

  const handleBankAccountClicked = useCallback(() => {
    setOpenDialog(Dialog.BankAccountInfo)
  }, [])

  const handleExpenseClicked = (data: 'applicant' | 'coApplicant') => {
    setEditingApplicant(data)
    setOpenDialog(Dialog.Expenses)
  }
  const handleEditBankruptciesClicked = (data: 'applicant' | 'coApplicant') => {
    setEditingApplicant(data)
    setOpenDialog(Dialog.Bankruptcies)
  }

  const handleConfirmAllIncomes = () => {
    return dispatchEffect(creditEffects.confirmAllIncomes({ id, financingProgramId })).then(() => {
      return dispatchEffect(fullCreditApplicationEffects.getById(id!, financingProgramId!))
    })
  }

  const handleApplicantUpdate = useCallback(
    async (applicantToUpdate: Applicant) => {
      const updatingCreditApplication =
        editingApplicant === 'applicant'
          ? { ...creditApplication, applicant: applicantToUpdate }
          : { ...creditApplication, coApplicant: applicantToUpdate }
      setDisabledDialogButton(true)
      await dispatchEffect(creditEffects.update(updatingCreditApplication))
      closeDialog()
    },
    [creditApplication, editingApplicant, dispatchEffect, closeDialog],
  )

  const handleLockingCreditApplication = useCallback(
    (minutes: number) => {
      if (creditApplication?.id) {
        const entityLock: CreditApplicationReservationDto = {
          financingProgramId: creditApplication.financingProgramId,
          creditApplicationId: creditApplication.id,
          durationInMinutes: minutes,
        }

        dispatchEffect(creditEffects.createCreditApplicationLock(entityLock)).catch(reportErrorToConsole)
      }
    },
    [creditApplication, dispatchEffect],
  )

  const handleAddingPrivateMessageToCreditApplication = useCallback(
    (message: string) => {
      if (creditApplication?.id) {
        const messageToAdd = {
          creditApplicationId: creditApplication.id,
          financingProgramId: creditApplication.financingProgramId,
          content: message,
          isPrivate: true,
        }
        dispatchEffect(messageEffects.createMessage(messageToAdd))
          .then(() => {
            setIsSideBarOpen(false)
          })
          .catch(reportErrorToConsole)
      }
    },
    [creditApplication, dispatchEffect],
  )
  const handleDeletingPrivateMessage = (message: CreditApplicationMessage) => {
    if (creditApplication?.id) {
      dispatchEffect(messageEffects.deleteMessage(message, creditApplication.financingProgramId)).catch(
        reportErrorToConsole,
      )
    }
  }

  const handleAddingTimeToLock = (lockWithChange: CreditApplicationReservation, minutes: number) => {
    if (minutes > 0) {
      const lockToUpdate = { ...lockWithChange }
      const date = new Date(lockToUpdate.expiresOn)
      date.setMinutes(date.getMinutes() + minutes)
      lockToUpdate.expiresOn = date
      dispatchEffect(creditEffects.updateCreditApplicationLock(lockToUpdate)).catch(reportErrorToConsole)
    }
  }

  const handleChangeCreditApplicationStatus = () => {
    if (creditApplication) {
      const currentStatus = creditApplication?.status
      if (currentStatus === ECreditApplicationStatus.Completed) return
      if (currentStatus !== ECreditApplicationStatus.Cancelled)
        dispatchEffect(
          creditEffects.changeStatus({
            financingProgramId: creditApplication.financingProgramId,
            creditApplicationId: creditApplication.id,
            status: ECreditApplicationStatus.Cancelled,
            versionTag: creditApplication.versionTag!,
          }),
        ).catch(reportErrorToConsole)
      else
        dispatchEffect(
          creditEffects.changeStatus({
            financingProgramId: creditApplication.financingProgramId,
            creditApplicationId: creditApplication.id,
            status: ECreditApplicationStatus.Active,
            versionTag: creditApplication.versionTag!,
          }),
        ).catch(reportErrorToConsole)
    }
  }

  const handleUpdateIncomes = async (applicantToUpdate: Applicant) => {
    if (creditApplication?.id) {
      const incomesToUpdate: EditIncomesDto = {
        incomes: applicantToUpdate.currentJobs,
        otherIncomes: applicantToUpdate.otherIncomes,
        creditApplicationId: creditApplication.id,
        applicantType: editingApplicant,
        financingProgramId: creditApplication.financingProgramId,
        versionTag: creditApplication.versionTag!,
      }
      setDisabledDialogButton(true)
      await dispatchEffect(creditEffects.updateIncomes(incomesToUpdate))
      closeDialog()
    }
  }

  const handleSetPaymentAccount = useCallback(
    async (data: BankAccount) => {
      if (creditApplication) {
        const bankAccountToUpdate: EditBankAccountDto = {
          creditApplicationId: creditApplication.id,
          bankName: data.bankName,
          transitNumber: data.transitNumber,
          institutionNumber: data.institutionNumber,
          accountNumber: data.accountNumber,
          financingProgramId: creditApplication.financingProgramId,
        }

        setDisabledDialogButton(true)
        await dispatchEffect(creditEffects.setPaymentAccount(bankAccountToUpdate))
      }
      closeDialog()
    },
    [creditApplication, dispatchEffect, closeDialog],
  )

  const handleGenerateCvtNumber = useCallback(() => {
    if (id && financingProgramId)
      dispatchEffect(creditEffects.createCvtNumber({ id, financingProgramId })).catch(reportErrorToConsole)
  }, [id, financingProgramId, dispatchEffect])

  const handlePrintCvt = useCallback(() => {
    if (id && financingProgramId)
      dispatchEffect(creditEffects.downloadCvtPdf({ id, financingProgramId })).catch(reportErrorToConsole)
  }, [financingProgramId, id, dispatchEffect])

  const [disableResendLinkButton, setDisableResendLinkButton] = React.useState(false)

  const handleElectronicSign = useCallback(() => {
    if (financingProgramId && id) {
      setDisableResendLinkButton(true)
      dispatchEffect(electronicSignatureEffects.resendLinkForElectronicSignature(financingProgramId, id)).catch(() =>
        setDisableResendLinkButton(false),
      )
    }
  }, [financingProgramId, id, dispatchEffect])

  const handleRefinanceCreditApplication = useCallback(() => {
    if (id && financingProgramId) {
      dispatchEffect(creditEffects.refinanceCreditApplication({ creditApplicationId: id, financingProgramId }))
        .then((data) => {
          navigate(`/Applications/${data.financingProgramId}/${data.id}/view`, { replace: true })
        })
        .catch(reportErrorToConsole)
    }
  }, [financingProgramId, id, dispatchEffect, navigate])

  const loadWorksheetRevisions = useCallback(() => {
    switch (financingProgramId) {
      case EFinancingProgram.Personal:
        return dispatchEffect(personalLoanWorksheetEffects.getWorksheetRevisions(id!))
      case EFinancingProgram.B2c:
        return dispatchEffect(b2cWorksheetEffects.getWorksheetRevisions(id!))
      default:
        return Promise.resolve()
    }
  }, [dispatchEffect, financingProgramId, id])

  const renderDialog = (param: Dialog) => {
    if (!creditApplication) return null
    switch (param) {
      case Dialog.Job:
        return (
          <EditIncomesDialog
            open
            title={
              editingApplicant === 'applicant'
                ? t('editCreditApplication.income.job.updateApplicantIncome')
                : t('editCreditApplication.income.job.updateCoapplicantIncome')
            }
            defaultValue={
              editingApplicant === 'applicant'
                ? creditApplication.applicant
                : creditApplication.coApplicant ?? undefined
            }
            onConfirm={handleUpdateIncomes}
            onCancel={closeDialog}
            disableConfirm={disabledDialogButton}
          />
        )
        break
      case Dialog.Bankruptcies:
        return (
          <EditBankruptciesDialog
            open
            creditApplication={creditApplication}
            applicant={
              editingApplicant === 'applicant'
                ? creditApplication.applicant
                : creditApplication.coApplicant ?? undefined
            }
            onConfirm={handleApplicantUpdate}
            onCancel={closeDialog}
            disableConfirm={disabledDialogButton}
          />
        )
        break
      case Dialog.Merch:
        return (
          <MerchantDialog
            open
            title={t('worksheet.modifyMerchant')}
            label={t('taskManager.merchantName')}
            onConfirm={onMerchantChanged}
            onCancel={closeDialog}
            allowMultiple={false}
            financingProgramId={creditApplication.financingProgramId}
            disableConfirm={disabledDialogButton}
            financingCompanyId={selectedCompany}
          />
        )
        break
      case Dialog.Task:
        return (
          <EditTaskDialog
            open
            title={t(editingTask.typeId ? 'tasks.editTask' : 'tasks.newTask')}
            defaultValue={editingTask}
            onConfirm={handleSaveTask}
            onCancel={closeDialog}
            disableConfirm={disabledDialogButton}
          />
        )
        break
      case Dialog.Comment:
        return (
          <NewTaskDialog
            open
            title={t('tasks.newComment')}
            disableConfirm={disabledDialogButton}
            onConfirm={handleSaveTaskComment}
            onCancel={closeDialog}
          />
        )
        break
      case Dialog.Suppression:
        return (
          <ConfirmActionDialog
            open
            title={t('tasks.suppression')}
            onConfirm={handleTaskDeletionConfirmed}
            onCancel={closeDialog}
            text={t('tasks.messageSuppression')}
          />
        )
        break
      case Dialog.Ban:
        return (
          <BanDialog
            open
            title={t('common.Confirm')}
            onConfirm={handleBanConfirmed}
            onCancel={closeDialog}
            text={t('ban.message')}
          />
        )
        break
      case Dialog.WorksheetHistory:
        if (creditApplication.financingProgramId === EFinancingProgram.Auto) {
          return (
            <AutoWorksheetHistoryDialog
              open={openDialog === Dialog.WorksheetHistory}
              worksheetRevisions={autoWorksheetRevisions}
              onClose={closeDialog}
            />
          )
        }
        if (creditApplication?.financingProgramId === EFinancingProgram.Personal) {
          return (
            <IFinanceWorksheetHistoryDialog
              open={openDialog === Dialog.WorksheetHistory}
              creditApplicationId={id}
              onClose={closeDialog}
              load={loadWorksheetRevisions}
              worksheetRevisions={personalWorksheetRevisions}
            />
          )
        }
        if (creditApplication?.financingProgramId === EFinancingProgram.B2c) {
          return (
            <IFinanceWorksheetHistoryDialog
              open={openDialog === Dialog.WorksheetHistory}
              creditApplicationId={id}
              onClose={closeDialog}
              load={loadWorksheetRevisions}
              worksheetRevisions={b2cWorksheetRevisions}
            />
          )
        }
        throw new Error('unexpected financing program')

        break
      case Dialog.Expenses:
        return (
          <EditExpenseDialog
            open={openDialog === Dialog.Expenses}
            title={
              editingApplicant === 'applicant'
                ? t('financeContract.updateApplicant')
                : t('financeContract.updateCoApplicant')
            }
            applicant={
              editingApplicant === 'applicant'
                ? creditApplication.applicant
                : creditApplication.coApplicant ?? undefined
            }
            onConfirm={handleApplicantUpdate}
            onCancel={closeDialog}
            financingProgramId={creditApplication.financingProgramId}
          />
        )
        break
      case Dialog.BankAccountInfo:
        return (
          <EditBankAcountDialog
            open={openDialog === Dialog.BankAccountInfo}
            bankAccount={creditApplication.paymentBankAccount}
            onConfirm={handleSetPaymentAccount}
            onCancel={closeDialog}
          />
        )
        break
      case Dialog.CreditDecisionHistory:
        return (
          <CreditDecisionHistoryDialog
            open={openDialog === Dialog.CreditDecisionHistory}
            creditApplicationId={id!}
            onClose={closeDialog}
            financingProgramId={financingProgramId!}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && creditApplication && merchant && (
        <>
          {lock && user?.rights.canEditCreditApp && (
            <CreditApplicationLockSideBar
              lock={lock}
              handleLockCreditApplication={handleLockingCreditApplication}
              handleDeleteLockCreditApplication={(creditLock: CreditApplicationReservation) => {
                return dispatchEffect(creditEffects.deleteCreditApplicationLock(creditLock))
              }}
              handleAddTimeToLock={handleAddingTimeToLock}
              app={creditApplication}
              handleTopSideBarOpen={handleTopSideBarOpen}
              currentUser={user}
              setCardHeight={setCardHeight}
            />
          )}

          {financingProgramId === EFinancingProgram.Personal && (
            <CreditApplicationPrivateMessageSideBar
              handleAddingMessageToCreditApplication={handleAddingPrivateMessageToCreditApplication}
              handleSideBarOpen={(currentState: boolean) => setIsSideBarOpen(!currentState)}
              isTopSideBarOpen={isTopSideBarOpen}
              isSideBarOpen={isSideBarOpen}
              app={creditApplication}
              cardHeight={cardHeight}
            />
          )}

          <div style={{ marginBottom: 25 }}>
            <Breadcrumb trees={breadCrumbs} />

            <PageError errors={error} />
            {(finalDecisionIsPending || isPrequalificationPending) && <PendingMessage />}
            <ApplicationResume
              creditApplication={creditApplication}
              creditApplicationMessage={creditApplicationMessage}
              merchant={merchant}
              onStatusChange={handleChangeCreditApplicationStatus}
              onMerchantChanged={() => setOpenDialog(Dialog.Merch)}
              creditApplicationCanBeLocked={canLockCreditApplication}
              loanId={creditApplication?.loanId}
              user={user}
              isAbleSendFundDepositConfirmation={isAbleSendFundDepositConfirmation}
              applicationStatus={applicationStatus!}
              editDisabled={editDisabled}
              finalDecisionIsPending={finalDecisionIsPending}
              allTasksRequiredForPayoutCompleted={areAllTasksRequiredForPayoutCompleted}
              allDocumentsRequiredForPayOutCompleted={areAllDocumentsRequiredForPayOut}
              hasDocumentsPendingApproval={hasDocumentsPendingApproval}
            />

            {financingProgramId === EFinancingProgram.Personal && <MerchantPaymentInstructions merchant={merchant} />}

            {showPreviousApplication && (
              <OtherApplicationList
                applicantPreviousApplications={applicantPreviousApplications}
                coApplicantPreviousApplications={coApplicantPreviousApplications}
                hasCoapplicant={creditApplication.coApplicant !== null}
              />
            )}
            <PrivateMessage
              messages={privateMessages}
              currentUser={user}
              handleDeletingPrivateMessage={handleDeletingPrivateMessage}
              creditAppCreationDate={creditApplication.createdOn}
            />
            {(applicantNormsMessage.length > 0 ||
              coapplicantNormsMessage.length > 0 ||
              commonNormsMessage.length > 0 ||
              worksheetNormsMessages.length > 0) && (
              <WarningMessageAccordion
                applicantCreditWarnings={applicantNormsMessage}
                coapplicantCreditWarnings={coapplicantNormsMessage}
                commonCreditWarnings={commonNormsMessage}
                worksheetWarnings={worksheetNormsMessages}
                expanded
              />
            )}

            {/** Task List */}
            <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      float: 'left',
                      border: '1px solid',
                      borderRadius: 2,
                      borderColor: theme.palette.divider,
                      p: 1,
                    }}
                  >
                    {t('tasks.tasks')}
                  </Typography>

                  {user?.rights.canCreateTasks && !editDisabled && (
                    <IconButtonWithTooltip
                      tooltip={t('tasks.newTask')}
                      icon={<AddTaskIcon color="primary" />}
                      onClick={() => handleEditTaskDialog(creditTaskSchema.getDefault() as CreditApplicationTask)}
                    />
                  )}
                  <AccountCircleIcon />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" component="div">
                    {t('tasks.toDo')}
                  </Typography>
                  <Checkbox onChange={handleShowOnlyTodoTasks} checked={showOnlyTodoTasks} />
                  <KeyboardDoubleArrowDownIcon />
                </Stack>
              </Stack>
              <Box sx={{ textAlign: 'center' }}>
                <TaskList
                  tasks={creditFilteredTasks}
                  onOpenTaskDialog={handleEditTaskDialog}
                  onOpenCommentDialog={handleNewComment}
                  onClickStatusButton={handleStatusChange}
                  onOpenTaskSuppressionDialog={handleTaskDeletionClicked}
                  creditApplication={creditApplication}
                  userCanEditTask={user?.rights.canManageTasks ?? false}
                  editDisabled={editDisabled}
                />
              </Box>
            </Paper>

            {/** Credit section */}
            <CreditSection
              creditApplication={creditApplication}
              onDisplayHistory={() => setOpenDialog(Dialog.CreditDecisionHistory)}
              handleEditBankruptciesClicked={handleEditBankruptciesClicked}
              editDisabled={editDisabled}
              user={user}
            />

            {/** Income section */}
            {!financingProgramConfig.noIncomeValidation && (
              <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
                <Grid container direction="row" rowSpacing={2} spacing={2} marginBottom={1}>
                  <Grid item>
                    <Typography variant="h5" component="h5" color="primary">
                      {t('editCreditApplication.income.label')} :
                    </Typography>
                  </Grid>
                  <Grid item>
                    {confirmAllIncomesStatus === ETasksStatus.Completed ? (
                      <Stack direction="row">
                        <CheckCircle color="success" fontSize="large" style={{ paddingRight: '5%' }} />
                        <Typography marginTop={0.3} variant="h5" component="h5" style={{ color: green[500] }}>
                          {t('editCreditApplication.income.incomeConfirmed')}
                        </Typography>
                      </Stack>
                    ) : (
                      <Button
                        variant="outlined"
                        disabled={
                          !canConfirmIncome || !user?.rights.canManageTasks || applicationIsDraft || editDisabled
                        }
                        onClick={handleConfirmAllIncomes}
                      >
                        {t('editCreditApplication.income.confirmIncomes')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Stack
                  direction={{ md: 'column', lg: 'row' }}
                  spacing={2}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Grid item width="100%">
                    <IncomeSection
                      applicant={creditApplication.applicant}
                      onOpenJobUpdateDialog={handleJobUpdateClicked}
                      isApplicant="applicant"
                      jobTasks={incomeTasks}
                      otherIncomeTasks={otherIncomeTasks}
                      creditApplicationId={creditApplication.id}
                      editDisabled={editDisabled}
                      handleSaveBankAccountRequest={handleSaveBankAccountRequest}
                      requiredExternalStep={findRequiredStepsByApplicantTypeAndExternalStepId(
                        EApplicantType.Applicant,
                        EExternalStep.BankAccount,
                      )}
                      user={user}
                      applicationIsDraft={applicationIsDraft}
                      creditApplication={creditApplication}
                      handleResetBankAccountRequest={handleResetdBankAccountRequest}
                      worksheetIsApproved={worksheetIsApproved}
                    />
                  </Grid>
                  <Grid item width="100%">
                    {creditApplication.coApplicant !== null && (
                      <IncomeSection
                        applicant={creditApplication.coApplicant}
                        onOpenJobUpdateDialog={handleJobUpdateClicked}
                        isApplicant="coApplicant"
                        jobTasks={incomeTasks}
                        otherIncomeTasks={otherIncomeTasks}
                        creditApplicationId={creditApplication.id}
                        editDisabled={editDisabled}
                        handleSaveBankAccountRequest={handleSaveBankAccountRequest}
                        requiredExternalStep={findRequiredStepsByApplicantTypeAndExternalStepId(
                          EApplicantType.CoApplicant,
                          EExternalStep.BankAccount,
                        )}
                        user={user}
                        applicationIsDraft={applicationIsDraft}
                        creditApplication={creditApplication}
                        handleResetBankAccountRequest={handleResetdBankAccountRequest}
                        worksheetIsApproved={worksheetIsApproved}
                      />
                    )}
                  </Grid>
                </Stack>
              </Paper>
            )}

            {/** worksheet section */}
            {creditApplication.financingProgramId === EFinancingProgram.Auto && (
              <AutoWorksheetInformationSection
                creditApplication={creditApplication}
                worksheet={autoWorksheet!}
                onWorksheetHistoryClicked={() => setOpenDialog(Dialog.WorksheetHistory)}
                user={user}
                editDisabled={editDisabled}
              />
            )}

            {creditApplication.financingProgramId === EFinancingProgram.Personal && (
              <PersonalWorksheetInformationSection
                creditApplication={creditApplication}
                worksheet={personalLoanWorksheet!}
                onWorksheetHistoryClicked={() => setOpenDialog(Dialog.WorksheetHistory)}
                user={user}
                defaultPaymentMethod={merchant.defaultPaymentMethod ?? EPaymentMethod.check}
                editDisabled={editDisabled}
                continueFinancingDisableReasonList={continueFinancingDisableReasonList}
                handleRefinanceCreditApplication={handleRefinanceCreditApplication}
              />
            )}

            {creditApplication.financingProgramId === EFinancingProgram.B2c && (
              <B2cWorksheetInformationSection
                creditApplication={creditApplication}
                worksheet={b2cWorksheet!}
                user={user}
                editDisabled={editDisabled}
                onWorksheetHistoryClicked={() => setOpenDialog(Dialog.WorksheetHistory)}
              />
            )}

            {creditApplication.financingProgramId === EFinancingProgram.AutoRsla && (
              <AutoRslaWorksheetInformationSection worksheet={autoRslaWorksheet!} />
            )}

            {/** Finance contract section */}
            <FinancementContractSection
              handleExpenseClicked={handleExpenseClicked}
              onClickGenerateCvt={handleGenerateCvtNumber}
              onClickPrintCvt={handlePrintCvt}
              onClickElectronicSign={handleElectronicSign}
              onClickBankAccount={handleBankAccountClicked}
              disableResendLinkButton={disableResendLinkButton}
              creditApplication={creditApplication}
              worksheet={autoWorksheet ?? personalLoanWorksheet ?? b2cWorksheet}
              electronicSignatureStatus={electronicSignatureStatus}
              user={user}
              editDisabled={editDisabled}
            />

            {/** Document section */}
            <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
              <DocumentList
                creditApplicationId={id ?? ''}
                financingProgramId={creditApplication.financingProgramId}
                editDisabled={editDisabled}
                user={user}
              />
            </Paper>

            {/* Dialog */}
            {renderDialog(openDialog)}
          </div>
        </>
      )}
    </>
  )
}

export default ViewCreditApplicationPage
